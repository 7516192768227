<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import Axios from "@/plugins/axios";
import DataLoader from "@/components/data-loader.vue";
import TextModal from "@/components/dashboard/text-modal.vue";
import Pagination from "@/components/dashboard/Pagination.vue";
import NotificationFormModal from "@/components/dashboard/notification/notification-form-modal.vue";
import NotificationTableCard from "@/components/dashboard/notification/notification-table-card.vue";

export default {
  components: {
    NotificationTableCard,
    NotificationFormModal,
    Pagination,
    DataLoader,
    Layout,
    PageHeader,
    TextModal
  },
  data() {
    return {
      notifications: [],
      showDataLoader: true,
      entityFormModal: false,
      notificationDescriptionModal: false,
      notificationDescription: null,
      activeProduct: null,
      next: null,
      previous: null,
      page: 1,
      totalItems: 0,
      query: '',
      url: ''
    }
  },
  mounted() {
    this.initDate()
    this.getNotifications()
  },
  methods: {
    openEntityFormModal() {
      this.entityFormModal = true
    },
    openUpdateProductModal(p) {
      this.activeProduct = p
      this.entityFormModal = true
    },
    closeModal(type) {
      switch (type) {
        case 'notification-form':
          this.activeProduct = null
          this.entityFormModal = false
          break
        case 'text-modal':
          this.productDescription = null
          this.productDescriptionModal = false
          break
      }
    },
    updateNotificationTable(notification) {
      this.notifications = this.notifications.filter(n => n.id !== notification.id)
      this.notifications.unshift(notification)
    },
    getNotifications() {
      Axios.get(this.url)
          .then(response => {
            this.setPagination(response)
            response.data["hydra:member"].map(p => {
              this.notifications.push(p)
            })

          })
          .catch(() => {
          })
          .finally(() => {
            this.showDataLoader = false
          })
    },
    initUrl() {
      this.url = '/admin/notifications?startDate=' + this.startDate + '&endDate=' + this.endDate + '&page=' + this.page
    },
    initDate(){
      const date = new Date()
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()

      this.startDate = year + '-01-01'
      this.endDate = year + '-' + month + '-' + day

      this.initUrl()
    },
    setPagination(response) {
      if (response.data["hydra:view"]) {
        if (response.data["hydra:view"]["hydra:next"]) {

          this.totalItems = response.data["hydra:totalItems"]
          this.next = response.data["hydra:view"]["hydra:next"]
          this.previous = response.data["hydra:view"]["hydra:previous"]
        }
      }
    },
    nextPage() {
      this.setCurrentPage(this.next)
      this.notifications = []
      this.getNotifications()
    },
    previousPage() {
      this.setCurrentPage(this.previous)
      this.notifications = []
      this.getNotifications()
    },
    navigate(page) {
      this.page = page
      this.notifications = []
      this.showDataLoader = true
      this.initUrl()
      this.getNotifications()
    },
    setCurrentPage(url) {
      const currentPage = Number(url.split('page=')[1])
      if (currentPage !== this.page) this.page = currentPage
      this.url = url.split('api/')[1]
      this.showDataLoader = true
    },
    search(query) {
      this.query = query
      this.showDataLoader = true
      this.notifications = []
      this.initUrl()
      this.getNotifications()
    },
    openProductDescriptionModal(p) {
      this.notificationDescription = p.content
      this.notificationDescriptionModal = true
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader
        :title="'Messages'"
        :button-title="'+ Message'"
        :show-search="false"
        show-date-filter
        @clickButton="openEntityFormModal"
    />
    <notification-form-modal
        @onModalClose="closeModal"
        @notificationCreated="updateNotificationTable"
        :active-product="activeProduct"
        :title="'Envoyez un messages aux utilisateurs'"
        v-model="entityFormModal"
    />
    <TextModal
        @onModalClose="closeModal"
        :content="notificationDescription"
        v-model="notificationDescriptionModal"
    />
    <DataLoader v-if="showDataLoader"/>
    <section>
      <div class="" v-if="notifications.length > 0">
        <div class="card">
          <div class="card-body">
            <notification-table-card
                :notifications="notifications"
                @openDescriptionModal="openProductDescriptionModal"
            />
            <Pagination
                :next="next"
                :previous="previous"
                :currentPage="page"
                :totalItems="totalItems"
                @navigate="navigate"
                @nextPage="nextPage"
                @previousPage="previousPage"
            />
          </div>
        </div>
      </div>
    </section>
  </Layout>
</template>