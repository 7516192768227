<script>
import Modal from "@/components/modal.vue";

export default {
  name: "text-modal",
  props: {
    title: {
      type: String,
      default: "Description"
    },
    content: {
      type: String,
      default: "Content"
    },
    value: {},
  },
  components: {Modal},
  data(){
    return {
      visible: false,
    }
  },
  watch: {
    value(newValue) {
      this.visible = newValue
    },
    visible(newValue){
      this.visible = newValue
    },
  },
  methods: {
    onModalClose(){
      this.visible = false
      this.$emit('onModalClose', 'text-modal')
    },
  }

}
</script>

<template>
  <Modal :title="title" @closeModal="onModalClose" v-model="visible">
    <div class="">
      <p>{{content}}</p>
    </div>
  </Modal>
</template>

<style scoped>

</style>