<script>
import Modal from "@/components/modal.vue";
import {helpers, maxLength, required} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import API from "@/plugins/axios-method";
import "@vueform/multiselect/themes/default.css";
import VueSelect from "vue-select";
import {SMS_LENGTH} from "@/helpers/constant/app";

export default {
  name: 'notification-form-modal',
  components: {VueSelect, Modal},
  props: {
    title: {
      type: String,
      default: "Créer Une Notification"
    },
    value: {},
  },
  setup(){
    return { v$: useVuelidate() }
  },
  data(){
    return {
      visible: false,
      textLength: 0,
      loadingRegions: true,
      content: null,
      selectedRegions: [],
      regions: [],

      isSubmitting: false,
      isSubmitted: false,
    }
  },
  mounted() {
    this.getRegions()
  },
  watch: {
    value(newValue) {
      this.visible = newValue
    },
    visible(newValue){
      this.visible = newValue
    },
  },
  methods: {
    verifyTextLength(e){
      this.textLength = e.length
    },
    onModalClose(){
      this.clearForm()
      this.visible = false
      this.isSubmitted = false
      this.isSubmitting = false
      this.$emit('onModalClose', 'notification-form')
    },
    formSubmit(){
      this.createNotification()
    },
    createNotification(){
      if (this.validateForm()) return
      const answer = confirm("Voulez-vous envoyer ce message? Il entrainera des cout sms")
      if (!answer) return
      this.isSubmitted = true

      const selectedRegionsUri = []
      this.selectedRegions.map(r => {
        selectedRegionsUri.push(r.value)
      })
      API.post('/admin/notifications', {
        content: this.content,
        regions: selectedRegionsUri
      })
          .then(response => {
            if (response.status === 201){
              this.createSuccess(response.data)
            }
          })
          .catch(() => {
            this.isSubmitted = false
          })

    },
    getRegions(){
      API.get('/regions_user')
          .then(response => {
            response.data['hydra:member'].map(r => {
              this.regions.push({
                label: r.name + ` (${r.userCount})`,
                value: r['@id'],
                id: r.uniqId
              })
            })
          })
          .finally(() => this.loadingRegions = false)
    },
    createSuccess(p){
      this.isSubmitting = false
      this.isSubmitted = false
      this.clearForm()
      this.onModalClose()
      this.$emit('notificationCreated', p)
    },
    clearForm(){
      this.v$.$reset()
      this.content = null
      this.selectedRegions = []
    },
    validateForm(){
      this.v$.$validate()
      return !!this.v$.$error
    },
  },
  computed: {
    SMS_LENGTH() {
      return SMS_LENGTH
    },
  },
  validations: {
    content: {
      required: helpers.withMessage("L'email est requis", required),
      maxLength: helpers.withMessage("Le message doit avoir " + SMS_LENGTH + " caractères max", maxLength(SMS_LENGTH))
    },
    selectedRegions: {
      required: helpers.withMessage("Le type est requis", required),
    },
  }
}
</script>

<template>
  <Modal :title="title" @closeModal="onModalClose" v-model="visible">
    <div class="">
      <form @submit.prevent="formSubmit">
        <div class="mb-3">
          <label for="telephone" class="form-label">Choissez les regions a envoyer le message </label>
          <vue-select :close-on-select="false" :loading="loadingRegions" :class="{'is-invalid': v$.selectedRegions.$errors.length }" :options="regions" v-model="selectedRegions" multiple/>
          <div class="invalid-feedback" v-for="error in v$.selectedRegions.$errors" :key="error.$uid">
            <span>{{ error.$message }}</span>
          </div>
        </div>

        <div class="mb-3">
          <label for="password" class="form-label py-0 my-0">Description</label>
          <p class="count-text text-center" :class="{'text-danger': SMS_LENGTH - textLength < 0 }"><span class="" :style="{fontSize: '10px'}">{{ SMS_LENGTH - textLength }}</span></p>
          <b-form-textarea @input="verifyTextLength" placeholder="Description" :class="{'is-invalid': v$.content.$errors.length || SMS_LENGTH - textLength < 0 }" v-model.trim="content"/>
          <div class="invalid-feedback" v-for="error in v$.content.$errors" :key="error.$uid">
            <span>{{ error.$message }}</span>
          </div>
        </div>

        <div class="mt-4">
          <button :disabled="isSubmitted" class="btn btn-success w-100" type="submit">
            <div v-if="isSubmitted" style="width: 20px; height: 20px" class="text-white spinner-border text-primary avatar-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <span v-if="!isSubmitted">Enregistrer</span>
          </button>
        </div>
      </form>
    </div>
  </Modal>

</template>

<style scoped>
  .count-text{
    padding: 0;
    margin: 0;
  }
</style>