<script>

import {getCreatedAndUpdatedDates, getTruncateString} from "@/helpers/functions";

export default {
  name: 'notification-table-card',
  components: {},
  props: {
    notifications: {
      type: Array,
      default(){
        return []
      }
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
    }
  },
  methods: {
    getTruncateString,
    getCreatedAndUpdatedDates,
    // updateStatus(id,status){
    //
    //   const confirmation = confirm("Voulez-vous vraiment changer le statut de ce produit ?")
    //
    //   if (!confirmation) return window.location.reload()
    //
    //   API.patch(`/products/${id}/status`, {
    //     status: Number(!status)
    //   })
    //       .then(() => {
    //
    //       })
    //       .catch(error => {
    //         console.log(error)
    //       })
    // },
    openDescriptionModal(n){
      this.$emit('openDescriptionModal', n)
    },
  }
}
</script>

<template>
  <div class="table-responsive">
    <table class="table table-hover table-striped align-middle table-nowrap mb-0">
      <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Titre</th>
        <th scope="col">Contenu</th>
        <th scope="col">Utilisateur touchés</th>
        <th scope="col">Statut</th>
        <th scope="col">Créé le</th>
<!--        <th scope="col">Action</th>-->
      </tr>
      </thead>
      <tbody>
      <tr v-for="(n, index) in notifications" :key="index">
        <th scope="row">{{index + 1}}</th>
        <td>{{ n.title }}</td>
        <td>
          <a href="javascript:void(0);" role="button" @click="openDescriptionModal(n)" class="link-primary">{{ getTruncateString(n.content) }}</a>
        </td>
        <td class="">{{ n.userCount }}</td>
        <td>
          <b-badge v-if="n.sentStatus === 0 || !n.sentStatus" variant="dark">No envoyé</b-badge>
          <b-badge v-else-if="n.sentStatus === 1" variant="success">Envoyé</b-badge>
        </td>
        <td>{{getCreatedAndUpdatedDates(n.createdAt)}}</td>
<!--        <td>-->
<!--          <div class="hstack gap-3 fs-15">-->
<!--            <router-link :to="'/admin/products/' + n.uniqId" class="link-primary">-->
<!--              <i class="ri-eye-fill"></i>-->
<!--            </router-link>-->
<!--          </div>-->
<!--        </td>-->
      </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>

</style>